import React from "react";

class ContactForm extends React.Component {
    render() {
        return (
            <div className="d-flex flex-column">
                <form className="d-flex flex-column form-style" method="post" action="#" data-netlify="true" name="contact">
                    <input type="hidden" name="form-name" value="contact" />
                    <div className="d-flex mb-2">
                    <input className="d-flex flex-grow" type="text" name="name" id="name" placeholder="Imię i Nazwisko" required />
                    </div>
                    <div className="d-flex mb-2">
                    <input className="d-flex flex-grow" type="email" name="email" id="email" placeholder="E-mail" required />

</div>
<div className="d-flex mb-2">
<textarea className="d-flex flex-grow" name="message" id="message" rows="4" required  placeholder="Treść"/>

</div>
<div className="d-flex mb-2">
<label className="checkbox-container" htmlFor="permission">
    
    Wyrażam zgodę na przetwarzanie danych osobowych podanych przeze mnie w formularzu niezbędnych do prowadzenia korespondencji z Autopomocni.pl Oświadczam, że zapoznałem/łam się z treścią poniższej informacji.
    <input type="checkbox" id="permission" name="permission" required/>
    <span className="checkmark"></span>
    </label>
</div>

                    <input className="btn btn-rounded green-bg main-color" type="submit" value="Wyślij" />
                </form>
            </div>
        );
    }
}

export default ContactForm;