import React from "react";

class ContactItem extends React.Component {
    constructor(props){
        super(props)
    }

    render() {
        return(
            <div className="d-flex flex-row align-items-center">
                <div className="d-flex xs-pr-0 pr-4">
                    <div className="icon-wrapper">
                        <img src={this.props.avatar} alt="autopomocni"></img>
                    </div>
                </div>
                <div className="d-flex flex-column">
                    <h2 className="my-0 green-color xs-text-center">{this.props.name}</h2>
                    <p className="text-default xs-text-center">{this.props.position}</p>
                    <a className="xs-text-center" href={`tel:${this.props.phone}`}>tel: {this.props.phone}</a>
                </div>
            </div>
        );
    }
}

export default ContactItem;