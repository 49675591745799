import React from 'react';
import Layout from './../components/Layout';
import rawLogo from './../images/logo_ic.png';
import contactMap from './../images/map.png';
import avatar1 from './../images/andrzej.png';
import avatar2 from './../images/pawel.png';
import avatar3 from './../images/irek.png';
import ContactItem from '../components/ContactItem';
import ContactForm from '../components/ContactForm';

const ContactPage = () => {
  const contactItems = [{
    name: "Andrzej Kiryluk",
    phone: "530008110",
    position: "AutoPromotor",
    avatar: avatar1
  }, {
    name: "Paweł Swięs",
    phone: "570757300",
    position: "AutoTechnik",
    avatar: avatar2
  }, {
    name: "Ireneusz Parafiniuk",
    phone: "791994480",
    position: "AutoKonsultant",
    avatar: avatar3
  }],
    firstCP = contactItems[0],
    secondCP = contactItems[1],
    thirdCP = contactItems[2];


  return (
    <Layout hideFooter="true">
      <div className="main-wrapper">
        <div className="d-flex flex-row xs-inverse">
          <div className="w-65 flex-column pr-2">
            <div className="d-flex flex-row mb-4">
              <div className="w-60 d-flex flex-column">
                <div className="d-flex flex-column">
                  <h1 className="green-color mt-0">Skontaktuj się</h1>
                  <div className="line__box d-flex min-height">
                    <div className="line__dasshed line__dashed--black w-100"></div>
                  </div>
                </div>
              </div>
              <div className="w-40 d-xs-none">
                <ContactItem phone={firstCP.phone} name={firstCP.name} position={firstCP.position} avatar={firstCP.avatar}></ContactItem>
              </div>
            </div>
            <div className="d-flex flex-row mb-4">
              <div className="w-60 d-flex flex-column">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row align-items-center">
                    <div className="w-50 d-flex">
                      <img className="m-auto" alt="" src={rawLogo}></img>
                    </div>
                    <div className="w-50 d-flex flex-column ">
                      <h2 className="green-color">API Cars Sp. z o.o.</h2>
                      <a href="mailto:info@autopomocni.pl">info@autopomocni.pl</a>
                      <a href="tel:+48570757300">tel. 570 757 300</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-40 d-xs-none">
                <ContactItem phone={secondCP.phone} name={secondCP.name} position={secondCP.position} avatar={secondCP.avatar}></ContactItem>
              </div>
            </div>
            <div className="d-flex flex-row mb-4">
              <div className="w-60 d-flex flex-column">
                <div className="d-flex flex-column">
                  <div className="d-flex flex-row align-items-end">
                    <div className="w-50 d-flex flex-column">
                      <p className="text-default">NIP 5372645629 </p>
                      <p className="text-default">REGON 382121160</p>
                      <p className="text-default">KRS 0000763919</p>
                    </div>
                    <div className="w-50 d-flex flex-column">
                      <p className="text-default">ul. Terebelska 115,</p>
                      <p className="text-default">21-500 Biała Podlaska</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-40 d-xs-none">
                <ContactItem phone={thirdCP.phone} name={thirdCP.name} position={thirdCP.position} avatar={thirdCP.avatar}></ContactItem>
              </div>
            </div>
            <div className="d-flex">
              {/* <img className="w-100" alt="" src={contactMap}></img> */}
            </div>
          </div>

          <div className="w-35 pl-2 mb-xs-4 xs-pl-0">
            <div className="d-flex justify-content-center">
              <h1 className="green-color mt-0">Napisz do nas</h1>
            </div>
            <ContactForm></ContactForm>
          </div>
          <div className="xs-only flex-column mb-xs-4">
            
              { contactItems.map((x, index)=>
                
                <div className=" mb-xs-2" key={index}>
              
                <ContactItem phone={x.phone} name={x.name} position={x.position} avatar={x.avatar}></ContactItem>

                </div>
              )}
        
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default ContactPage;